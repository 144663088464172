import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"
import { SEO, Slider, Navbar, Footer } from "../components"
import { Colors } from "../utils/constants"

import icon from "../images/project_details_icon.svg"
import Logo from "../images/Logo.svg"
import {
  credit,
  footerItems,
  footerText,
  links,
  navBarItems,
} from "../utils/data"

const ProjectPage = ({
  data: {
    project: { title, client, date, description, gallery, projectType, link },
  },
  pageContext,
}) => {
  const images = (gallery || []).map(({ file }, index) => ({
    title: index,
    imageUrl: file.url,
  }))

  return (
    <>
      <SEO title="Project Details" />
      <StyledContainer>
        <Navbar
          navBarItems={navBarItems}
          link={{ label: "GET IN TOUCH", href: "/contact" }}
          logo={Logo}
        />
        <StyledContent>
          <StyledCarousel>
            {images && <Slider images={images} />}
          </StyledCarousel>
          <StyledJobDetails>
            <div>
              {title && <h1>{title}</h1>}
              <div>
                {client?.name && <span>CLIENT: {client.name}</span>}
                {date && <span>DATE: {date}</span>}
                {projectType && <span>PROJECT TYPE: {projectType}</span>}
              </div>
              <div>
                {title && (
                  <h4>
                    {title}
                    {link && (
                      <a href={link.url} aria-label="Job details">
                        <img src={icon} alt="title link icon" />
                      </a>
                    )}
                  </h4>
                )}
                {description && <p>{description.description}</p>}
              </div>
            </div>
            <div>
              <Link to={`/projects/${pageContext.next}`}>Next Project</Link>
            </div>
          </StyledJobDetails>
        </StyledContent>
        <Footer
          footerItems={footerItems}
          logo={Logo}
          footerText={footerText}
          links={links}
          credit={credit}
          noVerticalSpacing
        />
      </StyledContainer>
    </>
  )
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 50px;
  ${media.md`
    flex-direction: row;
    margin-top: 57px;
  `}
`

const StyledCarousel = styled.div`
  position: relative;
  width: 100%;
  ${media.md`
    display:inline-block;
    width:50%;
  `};
`

const StyledJobDetails = styled(StyledCarousel)`
  box-sizing: border-box;
  h1 {
    color: ${Colors.RED};
    font-size: 16px;
    margin-top: 30px;
  }
  & > div:first-of-type {
    flex: 1;
  }
  & > div > div:first-of-type {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    span {
      color: ${Colors.GREY};
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  & > div > div:last-of-type {
    a {
      text-decoration: none;
    }
    p {
      line-height: 1.5;
      color: ${Colors.BLACK};
      margin: 20px 0px;
    }
  }

  & > div:last-of-type > a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    color: ${Colors.RED};
  }
  ${media.md`
  display:flex;
  flex-direction:column;
  padding-top:40px;
    padding-right:51px;
    padding-left:51px;
    padding-bottom:82px;
    h1 {
      font-size: 30px;
    }
    & > div > div:first-of-type {
      span {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
     }
    }
    & > a {
      font-size: 16px;
      margin-top: 50px;
      float: right;
    }
    & > div:last-of-type {
      display: flex;
      justify-content: flex-end;
    }
  `};
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export default ProjectPage

export const query = graphql`
  query($title: String!) {
    project: contentfulProject(title: { eq: $title }) {
      ...project
    }
  }
`
